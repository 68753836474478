import { useState } from "react";
import AnimationText from "../animation/AnimationText";
import "./Tabs.css";

function Tabs() {
    const [toggle, setToggle] = useState(1);

    function updateToggle(id) {
        setToggle(id);
    }

    const [isLoading, setLoading] = useState(true);
    const handleIframeLoad = () => {
        setLoading(false);
    };

    return (
        <div className="tabs">
            <AnimationText id="c">
                <ul className="tabs-wrapper">
                    <li
                        className={
                            toggle === 1 ? "tabs-item active" : "tabs-item"
                        }
                        onClick={() => updateToggle(1)}
                    >
                        С мебелью
                    </li>
                    <li
                        className={
                            toggle === 3 ? "tabs-item active" : "tabs-item"
                        }
                        onClick={() => updateToggle(3)}
                    >
                        Чистовая
                    </li>
                    <li
                        className={
                            toggle === 2 ? "tabs-item active" : "tabs-item"
                        }
                        onClick={() => updateToggle(2)}
                    >
                        Черновая
                    </li>
                </ul>
            </AnimationText>
            <div className={toggle === 1 ? "show-content" : "content"}>
                <iframe
                    style={
                        isLoading ? { display: "none" } : { display: "block" }
                    }
                    width="100%"
                    height="100%"
                    allow="xr-spatial-tracking; gyroscope; accelerometer"
                    allowfullscreen
                    src="https://kuula.co/share/collection/7JGNw?logo=0&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1"
                    onLoad={handleIframeLoad}
                ></iframe>
            </div>
            <div className={toggle === 2 ? "show-content" : "content"}>
                <iframe
                    style={
                        isLoading ? { display: "none" } : { display: "block" }
                    }
                    width="100%"
                    height="100%"
                    allow="xr-spatial-tracking; gyroscope; accelerometer"
                    allowfullscreen
                    src="https://kuula.co/share/collection/7J1Bz?logo=0&info=0&fs=1&vr=1&sd=1&initload=0&thumbs=1"
                    onLoad={handleIframeLoad}
                ></iframe>
            </div>
            <div className={toggle === 3 ? "show-content" : "content"}>
                <iframe
                    style={
                        isLoading ? { display: "none" } : { display: "block" }
                    }
                    width="100%"
                    height="100%"
                    allow="xr-spatial-tracking; gyroscope; accelerometer"
                    allowfullscreen
                    src="https://kuula.co/share/collection/7JDsb?logo=-1&info=0&fs=1&vr=1&sd=1&autorotate=0.08&autop=10&autopalt=1&thumbs=-1&alpha=0.60&inst=0"
                    onLoad={handleIframeLoad}
                ></iframe>
            </div>
        </div>
    );
}

export default Tabs;
